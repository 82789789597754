<template>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 pt-5 mt-5 pb-3">
          <img
            src="@/assets/images/ecosystem-partners.png?t=455"
            class="img-fluid"
            alt="ecosystem-partners"
          />
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8 pt-5 mt-5 pb-3">
    
      
          <h2>Our Ecosystem Partners</h2>
          <p>The TAMIL Crypto Utility Token network is growing at an impressive rate and TAMIL Crypto is forming new and lasting partnerships within the industry. 
          Here are some companies we are working with who are fostering a healthy ecosystem.</p>
  
            <div class="row">
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                
                      <img
                        src="@/assets/images/kisha-i-technology.jpeg"
                          class="img-fluid"
                          alt="KISHA I TECHNOLOGY"
                        />
                      <h5 class="card-title">KISHA I TECHNOLOGY</h5>
  
  
                    <p class="card-text">
                    
                      WhatsApp <a href='http://www.wasap.my/+60177923630/BuyTamilToken'>0177923630 </a><br>
                      kishaitech@gmail.com <br>
                      
                    </p>
                    
                  </div>
                </div>
              </div>
  
              <!--
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                
                      <img
                        src="https://www.smakintel.com/img/brand/logo.png"
                          class="img-fluid"
                          alt="SMAKINEL Solutions"
                        />
                        <br> <br> <br>
                      <h5 class="card-title">SMAKINEL Solutions</h5>
  
  
                    <p class="card-text">
                    
                      info@smakintel.com <br>
                      www.smakintel.com
                    </p>
                    
                  </div>
                </div>
              </div>
            --->
  
            </div>
  
        </div>
  
  
        <div class="card">
          <div class="card-header">
           <h2> Interested in working together? We'd love to hear from you!</h2>
          </div>
          <div class="card-body">
            
              <p>TAMIL Crypto Crypto Project loves to partner with forward thinking companies and services. <br>Contact us for more details..</p>
              
        
          </div>
        </div>
  
      </div>
    </div>
  </template>